import PropTypes from "prop-types";
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage} from "gatsby-plugin-image";
import { FaFacebookF, FaHome, FaLinkedin, FaTwitter } from "react-icons/fa";

import Header from "./header";

function Layout({ children }) {
  const { indexLogoImage } = useStaticQuery (
    graphql`
    query {
      indexLogoImage: file(relativePath: { eq: "DIA-T Logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 646 
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      },
    }
    `
  );
  const imageIndexLogoImage = getImage(indexLogoImage);

  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header />

      <main className="flex flex-col flex-1 max-w-4xl mx-auto px-4 md:px-4 w-full">
        {children}
      </main>

      <footer className="bg-black text-white bg-footer">
        <nav className="max-w-4xl mx-auto p-4 md:p-8 text-sm text-center">
          <ul className="text-lg list-none inline-menu font-light">
              <li>
                <a
                  href="https://www.hartpury.ac.uk/commercial/tech-box-park/"
                  target="_blank"
                  className="rounded-full w-8 h-8 hover:bg-primary justify-center items-center inline-flex"
                >
                  <FaHome className="inline"/>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/hartpury"
                  target="_blank"
                  className="rounded-full w-8 h-8 hover:bg-primary justify-center items-center inline-flex"
                >
                  <FaFacebookF className="inline"/>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/school/hartpury"
                  target="_blank"
                  className="rounded-full w-8 h-8 hover:bg-primary inline-flex justify-center items-center"
                >
                  <FaLinkedin className="inline"/>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/hartpury"
                  target="_blank"
                  className="rounded-full w-8 h-8 hover:bg-primary inline-flex justify-center items-center"
                >
                  <FaTwitter className="inline"/>
                </a>
              </li>
          </ul>
          <ul className="text-lg list-none inline-menu inline-menu-mobblock font-light">
            <li>Copyright &copy; {new Date().getFullYear()} Hartpury University and Hartpury College</li>
            <li>
              <a
                href="https://www.hartpury.ac.uk/legal/web-privacy-policy/"
                data-id="dropdown-menu-94"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.hartpury.ac.uk/legal/web-terms-and-conditions/"
                data-id="dropdown-menu-96"
              >
                Terms and Conditions
              </a>
            </li>
          </ul>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import { GatsbyImage, getImage} from "gatsby-plugin-image";
import Logo from "./logo";
import ERDF from "./erdf";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site, indexLogoImage } = useStaticQuery(graphql`
    query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        },
        indexLogoImage: file(relativePath: { eq: "DIA-T Logo.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 350 
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        },
    },
  `);
  const imageIndexLogoImage = getImage(indexLogoImage);

  return (
    <header className="">
      <div className="flex md:flex-row flex-col items-start mx-auto px-4 md:px-8 py-4">
        <div className="w-full hidden md:block">
          <Link className="flex items-center no-underline text-primary" to="/">
            <div className="hidden md:block">
              <ERDF />
            </div>
          </Link>
        </div>
        <div className="w-full text-center">
          <Link to={'/'}>
            <GatsbyImage image={imageIndexLogoImage} alt={"Hartpury Agriculture Digitial Innovation Farm"} className="mx-auto" style={{maxWidth: 646, maxHeight: 250}} />
          </Link>
        </div>
        <div className="w-full order-first md:order-none text-right">
          <a
            href="https://www.hartpury.ac.uk/"
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white bg-primary hover:bg-secondary transition-bg py-2 px-3 rounded-md"
            >
              Back
            </a>
        </div>

      </div>
    </header>
  );
}

export default Header;
